import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import { MemberLayout, MemberNav, MemberSidebar } from "../shared/index";
import authStore from "../../stores/authStore";
import memberSearchStore from "../../stores/memberSearchStore";
import bookImg from "../../styles/images/correspondence-book.png";
import MemberCard from "./MemberCard";
import Loading from "../shared/loading/Loading";
import searchIcon from "../../styles/images/member-inbox--pc/search-input.png";
import searchEmptyIcon from "../../styles/images/member-inbox--pc/search-empty.png";

const Correspondence: React.FC = () => {
  const history = useHistory();
  const { memberInfo, memberLoading } = useContext(authStore);
  const { list, pagination, loading, fetchList, nextPage, revert } = useContext(
    memberSearchStore
  );

  const [keyword, setKeyword] = useState("");

  const onScrollEvent = () => {
    if (!loading && pagination.hasNextPage) {
      const dom = document.getElementsByClassName(
        "member-search__list--wrapper"
      )[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        list.length < pagination.total
      ) {
        nextPage(keyword);
      }
    }
  };

  const authFn = () => {
    if (memberInfo && !memberInfo.listVip) {
      history.replace("/inbox");
    } else {
      revert();
      setKeyword("");
    }
  };

  useEffect(() => {
    if (!memberLoading) {
      authFn();
    }
  }, [memberLoading]);

  return (
    <div className="member-search">
      <Helmet>
        <title>搜索 ｜ 机器之心PRO</title>
      </Helmet>
      <MemberLayout>
        <MemberNav refreshFn={() => {}} />
        <MemberSidebar />
        <div className="member-search__content-wrapper">
          <div className="member-search__content">
            <div className="member-search__list">
              <div className="member-search__list--search">
                <Input
                  prefix={<img src={searchIcon} alt="icon" />}
                  allowClear
                  placeholder="输入关键词进行搜索"
                  onChange={(e: React.BaseSyntheticEvent) => {
                    if (e.target.value === "") {
                      revert();
                      setKeyword("");
                    }
                  }}
                  onPressEnter={(e: React.BaseSyntheticEvent) => {
                    setKeyword(e.target.value);
                    fetchList(e.target.value); //通讯
                  }}
                />
              </div>
              <div
                className="member-search__list--wrapper"
                onScrollCapture={onScrollEvent}
              >
                {list &&
                  list.length > 0 &&
                  list.map((item: any, index: number) => (
                    <MemberCard key={index} model={item.source} hasTags />
                  ))}
                {!loading && keyword && list && list.length === 0 && (
                  <div className="member-search__empty">
                    <img src={searchEmptyIcon} alt="empty" />
                    <p>暂未检索到相关内容</p>
                  </div>
                )}
                {loading && <Loading />}
              </div>
            </div>
          </div>
          <div className="member-search__blank">
            <img src={bookImg} alt="book" />
            <p>
              一份让您从此不再担心因业务繁忙而错失 AI &
              Robotics赛道良机的业内通讯
            </p>
          </div>
        </div>
      </MemberLayout>
    </div>
  );
};

export default observer(Correspondence);
