import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./member-card.scss";
import lockIcon from "../../styles/images/lock-icon.png";
import pdfImg from "../../styles/images/inbox-pdf.svg";
// import authStore from "../../stores/authStore";

interface Props {
  model: any;
  hasTags?: boolean;
}

const MemberCard: React.FC<Props> = (props: Props) => {
  const { model = {}, hasTags = false } = props;
  const history = useHistory();

  // const { memberInfo } = useContext(authStore);

  useEffect(() => {
    // api
  }, []);

  return (
    <div
      className="member-search-card"
      onClick={() => {
        if (model.category && model.category === "report") {
          history.push(`/report/${model.id}`);
        } else if (model.category && model.category === "interpretation") {
          history.push(`/reference/${model.id}`);
        } else {
          history.push(`/inbox/${model.id}`);
        }
      }}
    >
      <div className="member-search-card__info">
        <div
          className={`member-search-card__title ${model.unlocked &&
            "member-search-card__title--open"}`}
        >
          <p dangerouslySetInnerHTML={{ __html: model.title }} />
          {!model.unlocked && <img src={lockIcon} alt="lock" />}
        </div>
        {model.publishedTime && <p className="time">{model.publishedTime}</p>}
      </div>
      {model.summary && (
        <p>
          {model.category && model.category === "report" && (
            <img src={pdfImg} alt="pdf" />
          )}
          {model.summary}
        </p>
      )}
      {hasTags && model.hotTags && model.hotTags.length > 0 && (
        <div className="member-search-card__tags">
          {model.hotTags.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(MemberCard);
