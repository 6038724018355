import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Popover } from "antd";

import "./style.scss";
import authStore from "../../../stores/authStore";
import correspondenceStore from "../../../stores/correspondenceStore";
import logoIcon from "../../../styles/images/member-inbox--pc/logo@2x.png";
import jewelImg from "../../../styles/images/jewel-icon.svg";
import copperCash from "../../../styles/images/copper-cash-icon.svg";
import MemberCenter from "../member-center/MemberCenter";
import CostDialog from "./CostDialog";
import NewBuyDialog from "./NewBuyDialog";
import PaySuccessDialog from "./PaySuccessDialog";
import EditInfoDialog from "./EditInfoDialog";
import LoginDialog from "./LoginDialog";
import WxBindDialog from "./WxBindDialog";
import ToConvertDialog from "./ToConvertDialog";
import ConvertDialog from "./ConvertDialog";
import BuyIntegralDialog from "./BuyIntegralDialog";

interface Props {
  // popoverContent: React.ReactNode;
  // 刷新当前列表数据的函数
  refreshFn(): void;
}

const MemberNav: React.FC<Props> = (props: Props) => {
  const { refreshFn = () => {} } = props;
  const history = useHistory();
  const {
    memberLoading,
    memberInfo,
    windowExpire,
    currentUser,
    isNewMember,
    fetchMemberInfo,
    isCurrentUserEmpty,
  } = useContext(authStore);
  const { setDialog, setDiscount } = useContext(correspondenceStore);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      fetchMemberInfo();
      // setDialog("login", true);
    }, 500);
  }, []);

  return (
    <>
      <ToConvertDialog />
      <ConvertDialog />
      <WxBindDialog />
      <LoginDialog />
      <CostDialog
        onSuccess={() => {
          refreshFn();
        }}
      />
      <BuyIntegralDialog onSuccess={() => refreshFn()} />
      <NewBuyDialog onSuccess={() => refreshFn()} />
      <PaySuccessDialog />
      <div
        className="member-nav"
        onClickCapture={(e: React.BaseSyntheticEvent) => {
          if (isCurrentUserEmpty && !memberInfo) {
            setDialog("login", true);
            e.stopPropagation();
          }
        }}
      >
        <div className="logo-area">
          <img
            className="logo"
            src={logoIcon}
            alt="logo"
            onClick={() => history.replace("/inbox")}
          />
          {!memberLoading && memberInfo && (
            <EditInfoDialog
              name={memberInfo.name || memberInfo.nickname}
              company={memberInfo.company}
              position={memberInfo.position}
              onSubmit={() => fetchMemberInfo()}
            />
          )}
        </div>
        {!memberLoading &&
          (currentUser ? (
            <div className="user-area">
              <Popover
                placement="bottom"
                trigger="hover"
                content={<MemberCenter onClose={() => setOpen(false)} />}
                visible={open}
                onVisibleChange={() => setOpen(!open)}
                getPopupContainer={() =>
                  document.querySelector(".member-nav") || document.body
                }
              >
                <div className="my-info">
                  <div className="my-avatar">
                    <img
                      className="avatar"
                      src={memberInfo.avatarUrl}
                      alt="avatar"
                    />
                    {memberInfo.listVip && (
                      <img className="icon" src={jewelImg} alt="jewel" />
                    )}
                  </div>
                  {memberInfo.nickname}
                </div>
              </Popover>
              <div className="my-points">
                <img src={copperCash} alt="icon" />
                <p className="points">{memberInfo.points}</p>
              </div>
              <div className="my-time">
                {!memberInfo.listVip && !windowExpire ? (
                  <div
                    className="no-vip"
                    onClick={() => {
                      if (isNewMember) {
                        //新用户:从未购买过会员
                        setDialog("new-buy", true);
                      } else if (
                        (memberInfo.listVip &&
                          memberInfo.dueExpiredDay > 0 &&
                          memberInfo.dueExpiredDay <= 30) ||
                        (!memberInfo.listVip && windowExpire)
                      ) {
                        //即将到期、会员过期30天内windowExpire
                        setDiscount(true); // 设置是否折扣购买
                        setDialog("cost", true);
                      }
                    }}
                  >
                    尚未开通 · 订阅通讯会员获得超值权益
                  </div>
                ) : (
                  <div className="expiration-time">
                    {memberInfo.expiredAt0}
                    <p className="opacity-8">到期</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="btn__no-user"
              onClick={() => {
                // setDialog("login", true);
              }}
            >
              会员专属内容，请登录
            </div>
          ))}
      </div>
    </>
  );
};

export default observer(MemberNav);
