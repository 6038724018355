import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import "./member-inbox-center.scss";
import { isWeiXin, urlTranslate } from "../../utils/wx_tools";
import { Icon } from "../shared";
import AuthStore from "../../stores/authStore";
import MemberInboxStore from "../../stores/memberInboxStore";
import jewelImg from "../../styles/images/jewel-icon.svg";
import copperCash from "../../styles/images/copper-cash-icon.svg";
import codeImg from "../../styles/images/inbox-private--code.png";
import subscribeImg from "../../styles/images/subscribe-card.png";
import couponImg from "../../styles/images/coupon.png";
import CostDialog from "./CostDialog";
import NewBuyDialog from "./NewBuyDialog";
import EditInfoDialog from "./EditInfoDialog";
import PayErrorDialog from "./PayErrorDialog";
import PaySuccessDialog from "./PaySuccessDialog";
import ToConvertDialog from "./ToConvertDialog";
import ConvertDialog from "./ConvertDialog";
import { isMobile } from "../../utils/tools";

const MemberInboxCenter: React.FC = () => {
  const history = useHistory();
  const {
    isCurrentUserEmpty,
    memberInfo,
    fetchMemberInfo,
    memberLoading,
    currentUser,
    isNewMember,
    windowExpire,
  } = useContext(AuthStore);
  const { setDialog, setDiscount, wxLogin } = useContext(MemberInboxStore);

  const authFn = () => {
    if (isCurrentUserEmpty && !memberInfo) {
      wxLogin();
    }
    // 已登录未实名认证
    currentUser && !currentUser.mobile_verified && history.replace("/auth");
  };

  const openCost = (discount?: boolean) => {
    // 续费弹窗,discount:true折扣，false正价
    setDiscount(discount); // 设置是否折扣购买
    setDialog("cost", true);
  };

  useEffect(() => {
    if (isWeiXin()) {
      fetchMemberInfo();
      if (!(currentUser && !currentUser.mobile_verified)) {
        window.sessionStorage.removeItem("login");
      }
    } else {
      if (isMobile()) {
        history.replace("/inbox-pc");
      } else {
        urlTranslate(history);
      }
    }
  }, []);

  useEffect(() => {
    if (!memberLoading) {
      authFn();
    }
  }, [memberLoading]);

  return (
    !memberLoading && (
      <div className="member-inbox-center">
        <Helmet>
          <title>个人中心 ｜ 机器之心PRO</title>
        </Helmet>
        <CostDialog
          onSuccess={() => {
            window.localStorage.removeItem("login");
            fetchMemberInfo();
          }}
        />
        <NewBuyDialog onSuccess={() => fetchMemberInfo()} />
        <EditInfoDialog
          name={memberInfo.name || memberInfo.nickname}
          company={memberInfo.company}
          position={memberInfo.position}
          onSubmit={() => fetchMemberInfo()}
        />
        {/* 引入支付成功、支付失败弹窗，否则无法正常弹出 */}
        <PayErrorDialog />
        <PaySuccessDialog />
        <ToConvertDialog />
        <ConvertDialog />
        <div
          className={`member-inbox-center--wrapper
          ${
            !memberInfo.listVip && !windowExpire
              ? "member-inbox-center--nonvip"
              : memberInfo.listVip && memberInfo.dueExpiredDay > 30
              ? "member-inbox-center--vip"
              : "member-inbox-center--hastip"
          }
          `}
        >
          {/* 即将到期 + 窗口期 */}
          {((memberInfo.listVip &&
            memberInfo.dueExpiredDay > 0 &&
            memberInfo.dueExpiredDay <= 30) ||
            (!memberInfo.listVip && windowExpire)) && (
            <div
              className="member-inbox-center__tip"
              onClick={() => openCost(true)}
            >
              <p>我们将于 2025-1-1 调整会员续订优惠</p>
              现在续订仅需 ￥155.5/年，单期低至 3.24 元
            </div>
          )}
          {/* 即将到期 */}
          {/* {memberInfo.listVip &&
            memberInfo.dueExpiredDay > 0 &&
            memberInfo.dueExpiredDay <= 30 && (
              <div
                className="member-inbox-center__tip"
                onClick={() => openCost(true)}
              >
                您的会员剩余 <b>{memberInfo.dueExpiredDay}</b>{" "}
                天到期，会员期内续费立省359元
              </div>
            )} */}
          {/* 窗口期：会员过期30天内windowExpire */}
          {/* {!memberInfo.listVip && windowExpire && (
            <div
              className="member-inbox-center__tip"
              onClick={() => openCost(true)}
            >
              <span>{memberInfo.baseExpiredAt}</span>
              前续费立省359元！单期低至3.24元！
            </div>
          )} */}
          <div className="member-inbox-center__user-card">
            <div className="member-inbox-center__user">
              <img src={memberInfo.avatarUrl} alt="avatar" />
              <div className="user-info">
                <div>
                  {memberInfo.nickname}
                  {memberInfo.listVip && <img src={jewelImg} alt="jewel" />}
                </div>
                {<p>{memberInfo.bio || "暂无职务"}</p>}
              </div>
              <div
                className="user-edit"
                onClick={() => setDialog("edit-info", true)}
              >
                <Icon
                  name="edit-icon"
                  size={12}
                  color={
                    !memberInfo.listVip && !windowExpire ? "#778eaa" : "#fff"
                  }
                />
                编辑
              </div>
            </div>
            <div className="member-inbox-center__vip">
              <div className="integral">
                <p className="opacity-8">积分：</p>
                <img src={copperCash} alt="icon" />
                <p className="points">{memberInfo.points}</p>
              </div>
              {!memberInfo.listVip && !windowExpire ? (
                <div className="nonvip">
                  尚未开通 · 订阅通讯会员获得超值权益
                </div>
              ) : (
                <>
                  <div className="expiration-time">
                    <p className="opacity-8">到期时间：</p>
                    {memberInfo.expiredAt0}
                  </div>
                  <div className="renew-btn" onClick={() => openCost(true)}>
                    续订
                  </div>
                </>
              )}
            </div>
          </div>
          {!memberInfo.listVip && (isNewMember || !windowExpire) && (
            <img
              className="member-inbox-center__subscribe"
              src={subscribeImg}
              alt="card"
              onClick={() => {
                if (isNewMember) {
                  setDialog("new-buy", true);
                } else {
                  openCost(false);
                }
              }}
            />
          )}
          <div
            className="member-inbox-center__coupon"
            onClick={() => setDialog("to-convert", true)}
          >
            <div>
              <p>兑换优惠码</p>
              前往兑换机器之心PRO通讯会员
            </div>
            <img src={couponImg} alt="coupon" />
          </div>
          <div className="member-inbox-center__service">
            <div>
              <p>联系客服</p>
              咨询服务 | 售后支持 | 开发票
            </div>
            <img src={codeImg} alt="code" />
          </div>
          <div className="member-inbox-center__custom">
            <p>企业采购·专属定制</p>
            团购折扣 | 内部通讯 | 技术参考 | 赛道周报
            <div>详情咨询 pro@jiqizhixin.com</div>
          </div>
        </div>
      </div>
    )
  );
};

export default observer(MemberInboxCenter);
