import { createContext } from "react";
import { observable, action } from "mobx";
import { fetchSearchListApi } from "../api/index";
import { formatSearchList } from "../utils/formatMemberInboxData";

class MemberSearchStore {
  @observable list: any = [];
  @observable loading = false;
  @observable public pagination = {
    total: 0,
    current: 1,
    pageSize: 10,
    hasNextPage: true,
  };
  @observable public params: any = {
    page: 1,
    per: 10,
  };

  //去掉字符串两端的空格
  @action
  trim = str => {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  };
  //去除换行
  @action
  ClearBr = key => {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  };

  @action
  revert = () => {
    this.list = [];
    this.params = {
      page: 1,
      per: 20,
    };
    this.pagination = {
      total: 0,
      current: 1,
      pageSize: 10,
      hasNextPage: true,
    };
  };

  @action
  nextPage = (keyword: string) => {
    this.params.page += 1;
    this.fetchList(keyword, true);
  };

  // 搜索列表
  fetchList = async (keyword?: any, next?: boolean) => {
    if (!keyword) {
      return false;
    }
    this.loading = true;
    !next && this.revert();
    if (
      !(this.params.page > 1 && this.pagination.current === this.params.page)
    ) {
      const res = await fetchSearchListApi(
        keyword || "",
        this.params.page || 1,
        this.params.per || 10
      );
      // this.list、this.pagination赋值顺序不能变，
      // 因为this.pagination.current如果先赋值会改变判断条件的结果
      this.list =
        this.params.page > 1 && this.pagination.current !== this.params.page
          ? [...this.list, ...formatSearchList(res.contents)]
          : formatSearchList(res.contents);
      this.pagination = {
        total: res.totalCount,
        current: res.currentPage,
        pageSize: this.params.per,
        hasNextPage: res.hasNextPage,
      };
    }
    this.loading = false;
  };
}

export default createContext(new MemberSearchStore());
