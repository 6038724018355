import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import "./my-directory.scss";
import { replaceAll } from "../../utils/tools";
// import Icon from "../shared/icon/Icon";

interface Props {
  type: string;
  active?: number;
  style?: React.CSSProperties;
}

const MyDirectory: React.FC<Props> = (props: Props) => {
  const { type, active, style } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [directoryList, setDirectoryList] = useState<any>([]);
  const [conHeight, setConHeight] = useState<any>("0");
  const container: any = document.querySelector(`.${type}-detail__content`);
  //去掉字符串两端的空格
  function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  }
  //去除换行
  function ClearBr(key) {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  }

  const getAlls = () =>
    Array.from(document.querySelectorAll(`.${type}-detail-body *`)).filter(
      (item: any) => !!ClearBr(trim(item.innerText))
    );

  const getSections = () =>
    Array.from(
      document.querySelectorAll(`.${type}-detail-body section`)
    ).filter((item: any) => !!ClearBr(trim(item.innerText)));

  const getH3s = () =>
    Array.from(document.querySelectorAll(`.${type}-detail-body h3`)).filter(
      (item: any) => !!ClearBr(trim(item.innerText))
    );

  const initSectionItem = (sections: any, h3s: any) => {
    if (sections.length > 0) {
      sections.forEach((item: any, index: number) => {
        item._type = "section";
        item._children = [];
        h3s.forEach((cItem: any, cIndex: number) => {
          cItem._type = "h3";
          cItem._index = cIndex;
          if (sections[index + 1]) {
            if (
              item.sort < cItem.sort &&
              sections[index + 1].sort > cItem.sort
            ) {
              cItem._parent = item;
              item._children.push(cItem);
            }
          } else {
            if (item.sort < cItem.sort) {
              cItem._parent = item;
              item._children.push(cItem);
            }
          }
        });
      });
    } else {
      h3s.forEach((cItem: any, cIndex: number) => {
        cItem._type = "h3";
        cItem._index = cIndex;
      });
    }

    return {
      sections,
      h3s,
    };
  };

  const initDirectoryList = (res: any) => {
    console.log("res", res);
    const { sections, h3s } = res;
    const directoryList = [
      ...sections,
      ...h3s.filter((item: any) => !item._parent),
    ].sort((a: any, b: any) => a.sort - b.sort);
    setDirectoryList(directoryList);
  };

  useEffect(() => {
    const alls = getAlls();
    alls.forEach((item: any, index: number) => (item.sort = index));
    const sections = getSections();
    const h3s = getH3s();
    const res = initSectionItem(sections, h3s);
    initDirectoryList(res);
  }, []);

  useEffect(() => {
    container && setConHeight(`${container.clientHeight - 176}px`);
  }, [container]);

  useEffect(() => {
    setActiveIndex(active);
  }, [active]);

  const clickFn = (item: any) => {
    if (item._type === "section") {
      // directoryList.forEach((listItem: any) => {
      //   if (listItem.innerText === item.innerText) {
      //     listItem._expand = !listItem._expand;
      //   }
      // });
      setDirectoryList(directoryList.slice());
    } else {
      const items = getH3s();
      items.forEach((it: any) => {
        if (it.innerText === item.innerText) {
          container.scrollTo({
            top: item.offsetTop - 60,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    }
  };

  return (
    <div className="correspondence-my-directory" style={style}>
      <div
        className="correspondence-my-directory__body"
        style={{ maxHeight: conHeight }}
      >
        <div className="correspondence-my-directory__content">
          {directoryList.map((item: any, index: number) => (
            <div className={`correspondence-my-directory__wrapper`} key={index}>
              <div
                className={`correspondence-my-directory__item
                ${!(item._children && item._children.length > 0) &&
                  "correspondence-my-directory__item--after"}
                ${
                  item._index === activeIndex
                    ? "correspondence-my-directory__item--active"
                    : ""
                } ${item._index} ${item._type}`}
                onClick={() => clickFn(item)}
              >
                <span className="correspondence-my-directory__item--content">
                  <span>{replaceAll(item.innerText, "i", "")}</span>
                  {item._type === "section" && (
                    <span className="correspondence-my-directory__item--num">
                      {item._children.length}
                    </span>
                  )}
                </span>
                {/* {item._type === "section" && (
                  <span className="correspondence-my-directory__item--ctrl">
                    {item._expand ? (
                      <>
                        收起
                        <Icon name="pro-arrow-up" size={14} />
                      </>
                    ) : (
                      <>
                        展开
                        <Icon name="pro-arrow-down" size={16} />
                      </>
                    )}
                  </span>
                )} */}
              </div>
              <div
                className="correspondence-my-directory__item--show"
                // className={`${
                //   item._expand
                //     ? "correspondence-my-directory__item--show"
                //     : "correspondence-my-directory__item--hide"
                // }`}
              >
                {item._children &&
                  item._children.map((cItem: any, cIndex: number) => (
                    <div
                      key={cIndex}
                      className={`correspondence-my-directory__item correspondence-my-directory__item--after correspondence-my-directory__cItem ${
                        cItem._index === activeIndex
                          ? "correspondence-my-directory__item--active"
                          : ""
                      }`}
                      onClick={() => clickFn(cItem)}
                    >
                      {cItem.innerText}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(MyDirectory);
