import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./dialog.scss";
import { Dialog } from "../../shared";
// import integralIcon from "../../../styles/images/inbox-pay-integral.png";
import agreeIcon from "../../../styles/images/inbox-agree.png";
import disagreeIcon from "../../../styles/images/inbox-disagree.png";
import correspondenceStore from "../../../stores/correspondenceStore";
import { initWxPayCode, fetchWxPayStatus } from "../../../utils/wx_tools";
import AuthStore from "../../../stores/authStore";
import { fetchIntegralInfoApi, unlockMemberApi } from "../../../api/index";
import { onAllImgLoaded } from "../../../utils/tools";
import { buyInboxIntegralEvent } from "../../../utils/ga_events";
import checkOrigin from "../member-layout/checkOrigin";
import QRCode from "qrcode";

interface Props {
  onSuccess?(): any;
}

const BuyIntegralDialog: React.FC<Props> = (props: Props) => {
  const { onSuccess = () => {} } = props;
  const { id } = useParams();
  const { memberInfo, fetchMemberInfo } = useContext(AuthStore);
  const { dialog, setDialog, xy, jfId, setPurchase } = useContext(
    correspondenceStore
  );
  const onCancel = () => {
    setDialog("integral", false);
  };
  const [show, setShow] = useState<any>(false);
  const [info, setInfo] = useState<any>({});
  const [timer, setTimer] = useState<any>(null);
  const [agree, setAgree] = useState<any>(true);
  const purchase_source = checkOrigin();

  const fetchInfo = async () => {
    await fetchIntegralInfoApi().then((res: any) => {
      setInfo(res);
    });
  };

  const updatePayStatus = (data: any) => {
    fetchWxPayStatus(data.orderId).then(async () => {
      if (id) {
        await unlockMemberApi({
          id,
        });
      }
      // 标记是刚购买过的会员
      setPurchase("integral");
      fetchMemberInfo();
      onSuccess();
      setDialog("pay-success", true);
      buyInboxIntegralEvent({
        transaction_id: data.shortId,
        value: data.final_price / 100,
        item_id: data.proProductId,
        item_name: data.proProductName,
        price: data.final_price / 100,
        purchase_source,
        user_id: memberInfo.id,
        user: memberInfo.username,
      });
    });
  };

  useEffect(() => {
    setShow(false);
    if (dialog.integral) {
      fetchInfo().then(() => {
        onAllImgLoaded(document.querySelector(".buy-integral-dialog-1")).then(
          () => {
            setShow(true);
          }
        );
      });
    } else {
      clearInterval(timer);
    }
  }, [dialog.integral]);

  useEffect(() => {
    if (dialog.integral && info.price) {
      initWxPayCode(jfId).then(async (data: any) => {
        QRCode.toDataURL(data.codeUrl, { margin: 0 }).then(url => {
          const dom = document.querySelector(".qr-code");
          dom.setAttribute("src", url);
          clearInterval(timer);
          const t = setInterval(() => {
            updatePayStatus(data);
          }, 1000);
          setTimer(t);
        });
      });
    }
  }, [dialog.integral, info.price]);

  return (
    <Dialog
      visible={dialog.integral}
      style={{ padding: "0", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
      // needAuthCb={
      //   memberInfo && isWxLogin()
      //     ? null
      //     : () => {
      //         wxLogin();
      //       }
      // }
    >
      <div
        className={`buy-integral-dialog-1 ${show &&
          "buy-integral-dialog-1--show"}`}
      >
        <p>购买积分</p>
        <div className="integral-num">
          当前剩余 <span>{memberInfo ? memberInfo.points : 0}</span> 积分
        </div>
        <div className="integral-info">
          <img src={info.show_image_url} alt="" />
        </div>
        <div className="integral__code">
          <div className="code">
            <img className="qr-code" src="" alt="code" />
          </div>
          {info.final_price && (
            <div className="info">
              <p>{(info.final_price / 100).toFixed(2)}</p>
              <p>微信扫码支付，开通订阅</p>
              <div className="integral-tips">
                <span onClick={() => () => setAgree(true)}>
                  <img src={agree ? agreeIcon : disagreeIcon} alt="agreeIcon" />
                  我已阅读并同意{" "}
                </span>
                <span>
                  <a href={xy} target="_blank">
                    《用户服务协议》
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
        {/* {info.final_price && (
          <div
            className="integral-btn"
            onClick={() => {
              if (isCurrentUserEmpty && !memberInfo) {
                onCancel();
                wxLogin();
              } else {
                if (agree) {
                  onCancel();
                  wxJsPay(jfId)
                    .then((data: any) => {
                      // 标记是刚购买过的会员
                      setPurchase("integral");
                      fetchMemberInfo();
                      message.open({
                        type: "success",
                        duration: 3,
                        className: "ant-toast",
                        content: `${info.integral}积分已到账！`,
                      });
                      onSuccess();
                      buyInboxIntegralEvent({
                        transaction_id: data.shortId,
                        value: data.final_price / 100,
                        item_id: data.proProductId,
                        item_name: data.proProductName,
                        price: data.final_price / 100,
                        purchase_source,
                        user_id: memberInfo.id,
                        user: memberInfo.username,
                      });
                    })
                    .catch((err: any) => {
                      message.open({
                        type: "success",
                        duration: 3,
                        className: "ant-toast",
                        content: "支付失败，请重新尝试！",
                      });
                      // 很简单的意思 就是重新吊起购买
                      setDialog("integral", true);
                      debugFn(err);
                    });
                } else {
                  message.open({
                    type: "error",
                    duration: 3,
                    className: "ant-toast",
                    content: xyText,
                  });
                }
              }
            }}
          >
            立即充值
          </div>
        )} */}
      </div>
    </Dialog>
  );
};

export default observer(BuyIntegralDialog);
